import { useEffect } from "react"
import { Box, Paper, Typography, Button, TextField } from "@mui/material"
import SelectComponent from './singleSelect';


const EditRelatedField = ({ fieldName, label, option, setData, data, rowToEdit, page }) => {

  useEffect(() => {
    setData(oldData => { return { ...oldData, rules: rowToEdit[fieldName] } })
  }, [])

  const addRelatedField = () => {
    // for first rule
    if (!(fieldName in data)) {
      setData(oldData => { return { ...oldData, [fieldName]: [{}] } })
    } else {
      setData(oldData => { return { ...oldData, [fieldName]: [...oldData[fieldName] || [], {}] } })
    }
  }

  const deleteRelatedField = (index) => setData(oldData => {
    const relatedField = [...oldData[fieldName]]
    relatedField.splice(index, 1)
    return { ...oldData, [fieldName]: relatedField }
  })

  return (
    <Box
      component={Paper}
      noValidate
      mt={3}
      py={3}
      px={2}
    >
      <Typography
        variant='h6'
        pb={2}
      >{label}...</Typography>
      {
        data[fieldName] && data[fieldName].map((field, index) =>
          <div key={index} style={{ marginBottom: '1.5rem' }}>
            <Box>
              <Typography
                variant='h7'
              >
                #{index + 1}
              </Typography>
              <Button
                onClick={() => deleteRelatedField(index)}
              >
                Delete Rule
              </Button>
            </Box>
            {Object.keys(option).map(header => {
              if (option[header].type == "field") {
                return (
                  <SelectComponent
                    key={field[header]}
                    options={option}
                    header={header}
                    label={option[header].label || header}
                    setData={setData}
                    choice={false}
                    rowToEdit={field}
                  />
                )
              } else if (option[header].type == "decimal" || option[header].type == "integer") {
                return (
                  <TextField
                    key={header}
                    margin="normal"
                    type='number'
                    label={option[header].label}
                    defaultValue={field[header]}
                    fullWidth
                    disabled={option[header].read_only}
                    onChange={(e) => setData(oldState => {
                      const new_state = { ...oldState }
                      new_state[fieldName][index][header] = e.target.value
                      return new_state
                    })}
                  />
                )
              } else if (option[header].type == "string") {
                return (
                  <TextField
                    key={header}
                    margin="normal"
                    label={option[header].label}
                    defaultValue={field[header]}
                    disabled={option[header].read_only}
                    fullWidth
                    onChange={(e) => setData(oldState => {
                      const new_state = { ...oldState }
                      new_state[fieldName][index][header] = e.target.value
                      return new_state
                    })}
                  />
                )
              }
            })}
          </div>
        )
      }
      <Button
        onClick={addRelatedField}
      >
        Add new {label} +
      </Button>
    </Box>
  )
}

export default EditRelatedField
