
import { useEffect, useState } from "react"
import { Box, Paper, Typography, Button, CircularProgress } from "@mui/material"
import { useSnackbar } from 'notistack';
import SeriesEntry from './seriesEntry';


const Series = ({ options, setData, rowToEdit, data, isNew }) => {

  const field = 'series'

  if(data.series === undefined){
    delete data.series;
  }

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let fetched = false;
    async function getRules() {
      setData(rowToEdit.series1);
      setData(oldData => ({ ...oldData, ["series"]: rowToEdit.series }))
    }
    
    getRules().finally(() => setLoading(false))
    return () => fetched = true
  }, [])

  const addRelatedField = () => {
    if (!(field in data)) {
      setData(oldData => ({ ...oldData, [field]: [{}] }))
    } else {
      setData(oldData => ({ ...oldData, [field]: [...oldData[field] || [], {}] }))
    }
  }

  const remove = (index) => setData(oldData => {
    const relatedField = [...oldData['series']]
    relatedField.splice(index, 1)
    return { ...oldData, ['series']: relatedField }
  })

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <Box
        component={Paper}
        noValidate
        mt={3}
        py={3}
        px={2}
      >
        <Typography
          variant='h6'
          pb={2}
        >
          Series...
        </Typography>
          {
            loading
            ? 
              <CircularProgress />
            :
              <>
                {
                  data?.series && data?.series && data.series.filter(series => series !== "series").map((series, index) => 
                    <SeriesEntry
                      key={series.id1}
                      index={index}
                      options={options[field]}
                      setData={setData}
                      rowToEdit={series}
                      remove={() => remove(index)}
                    />
                )}
                <Button
                  onClick={addRelatedField}
                >
                  Add new Series +
                </Button>
              </>
            }
      </Box>
    </div>
  )
}

export default Series
