
import BaseSelect from "../components/baseSelect"
  

const Source = ({ setData, options, data }) => {

  // data.source = 'yt'
  const sourceOptions = options.source.choices

  return (
    <BaseSelect
      options={sourceOptions}
      onChange={(v) => setData(v)}
      value={data?.source ?? ''}
      label='Source'
    />
  )
}

export default Source
  
