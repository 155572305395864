import BaseSelect from "../components/baseSelect";

const CountryCode = ({setData, options, data}) => {
  // data.country_code = 'ROW'
  const countryOptions = options.country_code.choices;

  return (
    <BaseSelect
      options={countryOptions}
      onChange={(v) => setData(v)}
      value={data?.country_code ?? 'ROW'}
      label="Country Code"
    />
  );
};

export default CountryCode;
