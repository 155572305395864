import {useEffect} from "react";

const useScript = (url, onload) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    // script.async = true;
    script.onload = onload;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
