import {
  FormControl,
  Autocomplete,
  TextField,
  createFilterOptions,
} from "@mui/material";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  trim: true,
});

const BaseSelect = ({options, onChange, value, label}) => {
  // handles Both Choice [val, display_val] and Options [val] fields
  // handles Choice where raw data is val OR display_val
  const v =
    value.display_name ??
    options?.find((o) => o.value === value)?.display_name ??
    value ??
    "";

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        value={v}
        filterOptions={filterOptions}
        onChange={(e, v) => onChange(v?.value ?? v)}
        options={options ?? []}
        //options={"011"}
        getOptionLabel={(option) => option.display_name ?? option}
        isOptionEqualToValue={(option, val) =>
          (option?.display_name ?? option) === (val?.display_name ?? val)
        }
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </FormControl>
  );
};

export default BaseSelect;
