import { 
  FormControl,
  Autocomplete,
  TextField, 
  createFilterOptions
} from "@mui/material"
import { useState } from "react"

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  trim: true
})

const SelectComponent = ({ header, setData, options, label, onChange, rowToEdit }) => {

  const [value, setValue] = useState(() => {
    if (!(rowToEdit[header])) return ''
    const choices = options[header]?.choices
    if (!(choices)) return rowToEdit[header]
    const val = choices.find(o => o.value === rowToEdit[header]).display_name
    return val ?? rowToEdit[header]
  })
  
  return (
    <FormControl
      fullWidth
      key={header}
      margin="normal"
    >
      <Autocomplete
        value={value}
        filterOptions={filterOptions}
        onChange={(e, v) => {
          onChange ? 
            onChange(v, setValue) :
            (() => {
              console.log(v)
              setData(oldState => { return { ...oldState, [header]: v?.value ?? v } })
              setValue(v?.display_name ?? v)
            })()
        }}
        getOptionLabel={(option) => option.display_name ?? option}
        isOptionEqualToValue={(option, val) => (option?.value ?? option) === (val?.value ?? val) }
        options={options[header].choices ?? options[header].options ?? []}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </FormControl>
  )
}

export default SelectComponent
