import {useNavigate} from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  TextField,
} from "@mui/material";
import {useEffect, useState} from "react";
import sendRequest from "../services/sendRequest";
import {redirect} from "react-router-dom";

import {useSnackbar} from "notistack";

const ReportGen = ({open, close, selected, setRefresh}) => {
  const navigate = useNavigate();

  const [year, setYear] = useState(new Date().getFullYear());
  const [quarter, setQuarter] = useState(
    Math.floor((new Date().getMonth() + 2) / 3)
  );
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    // Calculate initial year and quarter whenever selected prop changes
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    let initialQuarter = Math.floor((currentMonth + 2) / 3);
    let initialYear = currentYear;

    if (initialQuarter === 1) {
      initialQuarter = 4;
      initialYear -= 1;
    } else {
      initialQuarter -= 1;
    }
    setFormData((prevState) => ({
      ...prevState,
      year: initialYear,
      quarter: initialQuarter,
    }));
  }, [selected]);

  const [formData, setFormData] = useState({
    year: new Date().getFullYear(),
    quarter: Math.floor((new Date().getMonth() + 2) / 3),
    deal_list: selected, // Assuming deal_list and tasks are other form data
    tasks: selected,
  });
  const generateReport = async () => {
    const updatedFormData = {
      ...formData,
      deal_list: selected, // Update deal_list with selected deals
      tasks: selected, // Assuming tasks need to be updated here
    };

    console.log(updatedFormData);
    const res = await sendRequest({
      requestType: "post",
      endpoint: "job/",
      requestData: updatedFormData,
    });
    console.log(res);
    if (res?.data?.id) {
      navigate(`/task?job=${res.data.id}`);
      close();
      setRefresh((oldState) => !oldState);
    } else {
      enqueueSnackbar("There was an error creating the job.", {
        variant: "warning",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        close();
        setFormData({});
      }}
    >
      <DialogTitle>
        Report Generation for {selected.length}{" "}
        {selected.length === 1 ? `Deal` : `Deals`}
      </DialogTitle>
      <DialogContent>
        <Box>
          <FormControl>
            <TextField
              type="number"
              label="Year"
              defaultValue={formData.year}
              sx={{marginY: 2}}
              onChange={(event) =>
                setFormData((oldData) => {
                  return {
                    ...oldData,
                    year: event.target.value,
                    //year: 2024,
                    deal_list: selected,
                  };
                })
              }
            />
            <TextField
              type="number"
              label="Quarter"
              sx={{marginY: 2}}
              defaultValue={formData.quarter}
              onChange={(event) =>
                setFormData((oldData) => {
                  return {
                    ...oldData,
                    // quarter: 1,
                    quarter: event.target.value,
                    tasks: selected,
                  };
                })
              }
            />
            <Button
              onClick={() => {
                generateReport();
              }}
            >
              Generate {selected.length === 1 ? `Report` : `Reports`}
            </Button>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReportGen;
