import sendRequest from "./sendRequest";

async function getOptions(page) {
  const optionsResponse = await sendRequest({
    requestType: "options",
    endpoint: `${page}`,
  });
  if(page==="permission")
    localStorage.setItem(
      `data`,
      JSON.stringify(optionsResponse.data)
    );
  if (optionsResponse.code) return false;

  const metadata = optionsResponse?.data?.actions?.POST;

  if (page === "programmes") {
    const programmeRulesOptions = await sendRequest({
      requestType: "options",
      endpoint: "programmerules/",
    });
    if (optionsResponse.code) return null;
    return {
      ...metadata,
      programme_rules: programmeRulesOptions?.data?.actions?.POST,
    };
  }

  if (page === "deals") {
    const programmeRulesOptions = await sendRequest({
      requestType: "options",
      endpoint: "dealrules/",
    });
    if (optionsResponse.code) return null;
    return {
      ...metadata,
      programme_rules: programmeRulesOptions?.data?.actions?.POST,
    };
  }

  return metadata;
}

export default getOptions;
