
import { useEffect, useState } from "react"
import { Box, Paper, Typography, Button, CircularProgress } from "@mui/material"
import { useSnackbar } from 'notistack';

import sendRequest from '../services/sendRequest'
import ProgrammeRuleEntry from './programmeRuleEntry';


const ProgrammeRule = ({ options, setData, rowToEdit, data, isNew }) => {

  const field = 'programme_rules'

  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    let fetched = false;
    async function getRules() {
      if (fetched || isNew) return
      const rules = await sendRequest({ requestType: "get", endpoint: `programmerules/?programme=${rowToEdit.custom_id}` })
      if (rules.code) {
        enqueueSnackbar('Failed to load Data!', { variant: 'error'})
      } else {
        setData(oldData => ({ ...oldData, [field]: rules.data }))
      }
    }
    
    getRules().finally(() => setLoading(false))
    return () => fetched = true
  }, [])

  const addRelatedField = () => {
    if (!(field in data)) {
      setData(oldData => ({ ...oldData, [field]: [{}] }))
    } else {
      setData(oldData => ({ ...oldData, [field]: [...oldData[field] || [], {}] }))
    }
  }

  const remove = (index) => setData(oldData => {
    const relatedField = [...oldData['programme_rules']]
    relatedField.splice(index, 1)
    return { ...oldData, ['programme_rules']: relatedField }
  })

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <Box
        component={Paper}
        noValidate
        mt={3}
        py={3}
        px={2}
      >
        <Typography
          variant='h6'
          pb={2}
        >
          Rules...
        </Typography>
          {
            loading
            ? 
              <CircularProgress />
            :
              <>
                {
                  data?.programme_rules && data.programme_rules.filter(rule => rule !== "series").map((rule, index) => 
                    <ProgrammeRuleEntry
                      key={rule.id}
                      index={index}
                      options={options[field]}
                      setData={setData}
                      rowToEdit={rule}
                      remove={() => remove(index)}
                    />
                )}
                <Button
                  onClick={addRelatedField}
                >
                  Add new Rule +
                </Button>
              </>
            }
      </Box>
    </div>
  )
}

export default ProgrammeRule
