import { styled, createTheme } from '@mui/material/styles';

const drawerwidth = 260

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#5188b9',
    },
    secondary: {
      main: '#F4B9B5',
    }
  }
});

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: '100vw',
  height: 'calc(100vh - 80px)',
  marginTop: 75,
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));



export {
  lightTheme,
  drawerwidth,
  Main,
}
