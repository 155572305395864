import { Autocomplete, Chip, TextField } from "@mui/material"

const ListSelect = ({fieldName, label, setData, data, rowToEdit}) => 
    <Autocomplete
        multiple
        options={[]}
        value={data[fieldName] || []}
        freeSolo
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
            ))
        }
        onChange={(e, value) => setData(oldData => { return {...oldData, [fieldName]: value}})}
        renderInput={(params) => (
            <TextField
                {...params}
                // variant="filled"
                label={fieldName}
            />
        )}
    />

export default ListSelect
