import {useEffect} from "react";
import {Box, Paper, Typography, Button} from "@mui/material";
import DealRuleEntry from "./dealRuleEntry";

const DealRule = ({options, setData, rowToEdit, data}) => {
  useEffect(() => {
    setData((oldData) => {
      return {...oldData, rules: rowToEdit.rules};
    });
  }, []);

  const addRelatedField = () => {
    if (!('rules' in data)) {
      setData(oldData => ({ ...oldData, 'rules': [{}] }))
    } else {
      setData(oldData => ({ ...oldData, 'rules': [...oldData['rules'] || [], {}] }))
    }
  };

  const remove = (index) => setData(oldData => {
    const relatedField = [...oldData['rules']]
    relatedField.splice(index, 1)
    return { ...oldData, 'rules': relatedField }
  })

  return (
    <div style={{marginBottom: "1.5rem"}}>
      <Box component={Paper} noValidate mt={3} py={3} px={2}>
        <Typography variant="h6" pb={2}>
          Rules...
        </Typography>
        {data?.rules &&
          data.rules.map((field, index) => {
            return (
              <DealRuleEntry
                key={field.id}
                index={index}
                options={options}
                setData={setData}
                rowToEdit={field}
                remove={() => remove(index)}
              />
            );
          })}
        <Button onClick={addRelatedField}>Add new Rule +</Button>
      </Box>
    </div>
  );
};

export default DealRule;
