import BaseSelect from "../components/baseSelect";

const PayToClient = ({setData, options, data}) => {
  // data.pay_to_client = 'Amazon Prime UK'
  const clientOptions = options.pay_to_client.options;

  return (
    <BaseSelect
      options={clientOptions}
      onChange={(v) => setData(v)}
      value={data?.pay_to_client ?? ""}
      label="Pay to Client"
    />
  );
};

export default PayToClient;
