import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import './export.css';

const UnknownAssetsExport =({open, close, selectedRows, selectedColumns}) => {
  const newHeaders = [];
  const { enqueueSnackbar } = useSnackbar();



  if(open === true) {
      if(selectedColumns.asset_id !== undefined) {
        const asset_id = "asset_id";
        if(selectedColumns.asset_id === true) {
          const asset_id = { label: "asset_id", key: "asset_id" };
          newHeaders.push(asset_id);
        } else {
          selectedRows = selectedRows.map(({ [asset_id]: _, ...rest }) => rest); 
        }
      } else {
          const asset_id = { label: "asset_id", key: "asset_id" };
          newHeaders.push(asset_id);
      }

      
      if(selectedColumns.cms !== undefined) {
        const cms = "cms";
        if(selectedColumns.cms === true) {
          const cms = { label: "cms", key: "cms" };
          newHeaders.push(cms);
          for (let i = 0; i < selectedRows.length; i++) {
            if(selectedRows[i].cms === "main"){
              selectedRows[i].cms = "Main";
            } else if(selectedRows[i].cms === "deutschland"){
              selectedRows[i].cms = "Deutschland";
            } else if(selectedRows[i].cms === "family"){
              selectedRows[i].cms = "Family";
            } else if(selectedRows[i].cms === "sports"){
              selectedRows[i].cms = "Sport";
            } else if(selectedRows[i].cms === "misc"){
              selectedRows[i].cms = "Misc";
            } else if(selectedRows[i].cms === "affiliate"){
              selectedRows[i].cms = "Affiliate";
            } else if(selectedRows[i].cms === "sonypictures"){
              selectedRows[i].cms = "Sony Pictures";
            } else if(selectedRows[i].cms === "intlparacomm"){
              selectedRows[i].cms = "Intl Para Comm";
            } else if(selectedRows[i].cms === "usaffiliate"){
              selectedRows[i].cms = "US Affiliate";
            } else if(selectedRows[i].cms === "sony_kids"){
              selectedRows[i].cms = "Sony Kids";
            } 
          }
        } else {
          selectedRows = selectedRows.map(({ [cms]: _, ...rest }) => rest); 
        }
      } else {
        const cms = { label: "cms", key: "cms" };
          newHeaders.push(cms);
          for (let i = 0; i < selectedRows.length; i++) {
            if(selectedRows[i].cms === "main"){
              selectedRows[i].cms = "Main";
            } else if(selectedRows[i].cms === "deutschland"){
              selectedRows[i].cms = "Deutschland";
            } else if(selectedRows[i].cms === "family"){
              selectedRows[i].cms = "Family";
            } else if(selectedRows[i].cms === "sports"){
              selectedRows[i].cms = "Sport";
            } else if(selectedRows[i].cms === "misc"){
              selectedRows[i].cms = "Misc";
            } else if(selectedRows[i].cms === "affiliate"){
              selectedRows[i].cms = "Affiliate";
            } else if(selectedRows[i].cms === "sonypictures"){
              selectedRows[i].cms = "Sony Pictures";
            } else if(selectedRows[i].cms === "intlparacomm"){
              selectedRows[i].cms = "Intl Para Comm";
            } else if(selectedRows[i].cms === "usaffiliate"){
              selectedRows[i].cms = "US Affiliate";
            } else if(selectedRows[i].cms === "sony_kids"){
              selectedRows[i].cms = "Sony Kids";
            }
          }
      }

      if(selectedColumns.programme !== undefined) {
        const programme = "programme";
        if(selectedColumns.programme === true) {
          const programme = { label: "programme", key: "programme" };
          newHeaders.push(programme);
        } else {
          selectedRows = selectedRows.map(({ [programme]: _, ...rest }) => rest); 
        }
      } else {
        const programme = { label: "programme", key: "programme" };
          newHeaders.push(programme);
      }


      if(selectedColumns.quarter !== undefined) {
        const quarter = "quarter";
        if(selectedColumns.quarter === true) {
          const quarter = { label: "quarter", key: "quarter" };
          newHeaders.push(quarter);
        } else {
          selectedRows = selectedRows.map(({ [quarter]: _, ...rest }) => rest); 
        }
      } else {
        const quarter = { label: "quarter", key: "quarter" };
          newHeaders.push(quarter);
      }


      if(selectedColumns.revenue !== undefined) {
        const revenue = "revenue";
        if(selectedColumns.revenue === true) {
          const revenue = { label: "revenue", key: "revenue" };
          newHeaders.push(revenue);
        } else {
          selectedRows = selectedRows.map(({ [revenue]: _, ...rest }) => rest); 
        }
      } else {
        const revenue = { label: "revenue", key: "revenue" };
          newHeaders.push(revenue);
      }


      if(selectedColumns.source !== undefined) {
        const source = "source";
        if(selectedColumns.source === true) {
          const source = { label: "source", key: "source" };
          newHeaders.push(source);
        } else {
          selectedRows = selectedRows.map(({ [source]: _, ...rest }) => rest); 
        }
      } else {
        const source = { label: "source", key: "source" };
          newHeaders.push(source);
      }

      if(selectedColumns.year !== undefined) {
        const year = "year";
        if(selectedColumns.year === true) {
          const year = { label: "year", key: "year" };
          newHeaders.push(year);
        } else {
          selectedRows = selectedRows.map(({ [year]: _, ...rest }) => rest); 
        }
      } else {
        const year = { label: "year", key: "year" };
          newHeaders.push(year);
      }
  
    const handleClose = () => {
      close();
    };

    const handleDownloadAndClose = () => {
      handleSuccess();
      close();
    };

    const handleSuccess = () => {
      enqueueSnackbar('The .CSV file downloaded Successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        autoHideDuration: 3000,
      });
    };
    
    const csvLink =  {
        filename : "UnknownAssetsExportData.csv",
        headers : newHeaders,
        data : selectedRows,
    };    
  
    return (
      <Dialog
      open={open}
      onClose={() => {
        close();
      }}
      >
        <DialogContent>
        <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  height: '300px',
                  width: '600px',
                },
              }}>
              <DialogTitle sx={{ textAlign: 'center' }}>Download CSV</DialogTitle>
              <DialogContent sx={{ marginBottom: 2 }}>
                <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                  Are you sure want to download the .csv?
                </DialogContentText>             
                <DialogActions sx={{ textAlign: 'center'}}>
                    <CSVLink {...csvLink} class="csv-link"
                    onClick={handleDownloadAndClose}> Download CSV</CSVLink>
                </DialogActions>
              </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} class="dialog-close-button">Close</Button>
                </DialogActions>
            </Dialog>
        </DialogContent>
      </Dialog>
    );  
  }  
};

export default UnknownAssetsExport;
