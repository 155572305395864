import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {

    const access = localStorage.getItem(`${process.env.REACT_APP_GCP_PROJECT}_access`)
    const refresh = localStorage.getItem(`${process.env.REACT_APP_GCP_PROJECT}_refresh`)

    // return credential ? <Outlet /> : <Navigate to="/"/>
    return (access && refresh) ? <Outlet /> : <Navigate to="/"/>
}

export default PrivateRoutes
