import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import './export.css';

const AssetsExport =({open, close, selectedRows, selectedColumns}) => {

  const newHeaders = [];
  const { enqueueSnackbar } = useSnackbar();

  if(open === true) {
      if(selectedColumns.client !== undefined) {
        const client = "client";
        if(selectedColumns.client === true) {
          const client = { label: "client", key: "client" };
          newHeaders.push(client);
        } else {
          selectedRows = selectedRows.map(({ [client]: _, ...rest }) => rest); 
        }
      } else {
          const client = { label: "client", key: "client" };
          newHeaders.push(client);
      }

      
      if(selectedColumns.cms !== undefined) {
        const cms = "cms";
        if(selectedColumns.cms === true) {
          const cms = { label: "CMS", key: "cms" };
          newHeaders.push(cms);
        } else {
          selectedRows = selectedRows.map(({ [cms]: _, ...rest }) => rest); 
        }
      } else {
        const cms = { label: "CMS", key: "cms" };
          newHeaders.push(cms);
      }


      if(selectedColumns.custom_id !== undefined) {
        const custom_id = "custom_id";
        if(selectedColumns.custom_id === true) {
          const custom_id = { label: "custom_id", key: "custom_id" };
          newHeaders.push(custom_id);
        } else {
          selectedRows = selectedRows.map(({ [custom_id]: _, ...rest }) => rest); 
        }
      } else {
        const custom_id = { label: "custom_id", key: "custom_id" };
          newHeaders.push(custom_id);
      }


      if(selectedColumns.episode !== undefined) {
        const episode = "episode";
        if(selectedColumns.episode === true) {
          const episode = { label: "episode", key: "episode" };
          newHeaders.push(episode);
        } else {
          selectedRows = selectedRows.map(({ [episode]: _, ...rest }) => rest); 
        }
      } else {
        const episode = { label: "episode", key: "episode" };
          newHeaders.push(episode);
      }


      if(selectedColumns.assetsId !== undefined) {
        const id = "assetsId";
        if(selectedColumns.assetsId === true) {
          const id = { label: "id", key: "assetsId" };
          newHeaders.push(id);
        } else {
          selectedRows = selectedRows.map(({ [id]: _, ...rest }) => rest); 
        }
      } else {
        const id = { label: "id", key: "assetsId" };
          newHeaders.push(id);
      }


      if(selectedColumns.programme !== undefined) {
        const programme = "programme";
        if(selectedColumns.programme === true) {
          const programme = { label: "programme", key: "programme" };
          newHeaders.push(programme);
        } else {
          selectedRows = selectedRows.map(({ [programme]: _, ...rest }) => rest); 
        }
      } else {
        const programme = { label: "programme", key: "programme" };
          newHeaders.push(programme);
      }


      if(selectedColumns.series !== undefined) {
        const series = "series";
        if(selectedColumns.series === true) {
          const series = { label: "series", key: "series" };
          newHeaders.push(series);
        } else {
          selectedRows = selectedRows.map(({ [series]: _, ...rest }) => rest); 
        }
      } else {
        const series = { label: "series", key: "series" };
          newHeaders.push(series);
      }


      if(selectedColumns.source !== undefined) {
        const source = "source";
        if(selectedColumns.source === true) {
          const source = { label: "source", key: "source" };
          newHeaders.push(source);
        } else {
          selectedRows = selectedRows.map(({ [source]: _, ...rest }) => rest); 
        }
      } else {
        const source = { label: "source", key: "source" };
          newHeaders.push(source);
      }    
  
    const handleDownloadAndClose = () => {
      handleSuccess();
      close();
    };

    const handleClose = () => {
      close();
    };

    const handleSuccess = () => {
      enqueueSnackbar('The .CSV file downloaded Successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        autoHideDuration: 3000,
      });
    };
    
    const csvLink =  {
        filename : "assetsExportData.csv",
        headers : newHeaders,
        data : selectedRows,
    };
  
    return (
      <Dialog
      open={open}
      onClose={() => {
        close();
      }}
      >
        <DialogContent>
          <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  height: '300px',
                  width: '600px',
                },
              }}>
              <DialogTitle sx={{ textAlign: 'center' }}>Download CSV</DialogTitle>
              <DialogContent sx={{ marginBottom: 2 }}>
                <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                  Are you sure want to download the .csv?
                </DialogContentText>             
                <DialogActions sx={{ textAlign: 'center'}}>
                    <CSVLink {...csvLink} class="csv-link" onClick={handleDownloadAndClose}> Download CSV</CSVLink>
                </DialogActions>
              </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} class="dialog-close-button">Close</Button>
                </DialogActions>
            </Dialog>
        </DialogContent>
      </Dialog>
    );
  }  
};

export default AssetsExport;
