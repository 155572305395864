
import { Box, Typography, Button, TextField, InputAdornment, FormControl } from "@mui/material"
import { useSnackbar } from 'notistack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const SeriesEntry = ({ index, options, setData, rowToEdit, remove }) => {

  const handleText = (value, header) => setData(oldData => {
    var relatedField = [...oldData['series']]
    if (!(value.target.value)) {
      delete relatedField[index][header];
      return { ...oldData, ['series']: relatedField}
    }

    relatedField[index][header] = value.target.value
    return {...oldData, ['series']: relatedField}
  })

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box>
          <Typography
            variant='h7'
          >
            #{index + 1}
          </Typography>
          <Button
            onClick={() => remove()}
          >
            Delete Series
          </Button>
        </Box>
        <TextField
          key="id"
          margin="normal"
          type='text'
          label={options.id?.label}
          value={rowToEdit.id}
          fullWidth
          onChange={(e) => handleText(e, 'id')}
        />
        <TextField
          key="name"
          margin="normal"
          type='text'
          label={options.name?.label}
          value={rowToEdit.name}
          fullWidth
          onChange={(e) => handleText(e, 'name')}
        />
        <FormControl
          fullWidth
          margin="normal"
        >
        </FormControl>
      </LocalizationProvider>
    </div>
  )
}

export default SeriesEntry
