import {redirect} from "react-router-dom";
import axios from "axios";

// const API_ENDPOINT = "http://127.0.0.1:8000/api/v1/";
const API_ENDPOINT = `${window.location.origin}/api/v1/`;

const sendRequest = async ({
  requestType,
  endpoint,
  requestData,
  requestParams,
}) => {
  let response;

  const access = localStorage.getItem(
    `${process.env.REACT_APP_GCP_PROJECT}_access`
  );

  try {
    response = await axios({
      method: requestType,
      url: `${API_ENDPOINT}${endpoint}`,
      data: requestData,
      params: requestParams,
      headers: {Authorization: `Bearer ${access}`},
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_access`);
      localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_refresh`);
      redirect("/");
    }
    response = error;
  }

  return response;
};

export default sendRequest;
