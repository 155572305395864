
import { useState } from 'react'
import { Box, Typography, Button, TextField, InputAdornment, FormControl } from "@mui/material"
import { useSnackbar } from 'notistack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Source from '../fields/source';
import PayToClient from '../fields/payToClient';
import CountryCode from '../fields/countryCode';


const ProgrammeRuleEntry = ({ index, options, setData, rowToEdit, remove }) => {

  const { enqueueSnackbar } = useSnackbar()
  const [rev, setRev] = useState(rowToEdit.client_rev_share ?? null)
  const [startDate, setStartDate] = useState(rowToEdit.start_date ?? null)
  const [endDate, setEndDate] = useState(rowToEdit.end_date ?? "2999-01-01")
  const handleNumeric = (value, header, setter) =>
    setData((oldData) => {
      if(isNaN(value)) {
        value = null;
        oldData.programme_rules[index][header]  = null;
      }
      const relatedField = [...oldData['programme_rules']];

      const { min_value, max_value } = options[header]
      if (min_value > value || max_value < value){
        enqueueSnackbar(
          `Client Share must be between ${min_value} and ${max_value}`,
          { variant: "warning", preventDuplicate: true }
        )
        return oldData
      }

      relatedField[index][header] = value
      setter(value)
      return {...oldData, ['programme_rules']: relatedField}
    })

  const handleText = (value, header) => setData(oldData => {
    const relatedField = [...oldData['programme_rules']]
    if (!(value)) {
      delete relatedField[index][header];
      return { ...oldData, ['programme_rules']: relatedField}
    }

    relatedField[index][header] = value
    return {...oldData, ['programme_rules']: relatedField}
  })

  const handleDate = (value, header, setter) => setData(oldData => {
    const relatedField = [...oldData['programme_rules']]
    if (!(value)) {
      relatedField[index][header] = {}
      return { ...oldData, ['programme_rules']: relatedField}
    }

    relatedField[index][header] = value
    setter(value)
    return {...oldData, ['programme_rules']: relatedField}
  })

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box>
          <Typography
            variant='h7'
          >
            #{index + 1}
          </Typography>
          <Button
            onClick={() => remove()}
          >
            Delete Rule
          </Button>
        </Box>
        <TextField
          key="clientRevShare"
          margin="normal"
          type='number'
          disabled={options.client_rev_share.read_only}
          label={options.client_rev_share?.label}
          value={rev}
          fullWidth
          onChange={(e) => handleNumeric(e.target.valueAsNumber, 'client_rev_share', setRev)}
          inputProps={{
            step: 1,
            min: options.client_rev_share.min_value,
            max: options.client_rev_share.max_value,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <CountryCode
          setData={(v) => handleText(v, 'country_code')}
          options={options}
          data={rowToEdit ?? {}}
        />
        <Source
          setData={(v) => handleText(v, 'source')}
          options={options}
          data={rowToEdit ?? {}}
        />
        <PayToClient
          setData={(v) => handleText(v, 'pay_to_client')}
          options={options}
          data={rowToEdit ?? {}}
        />
        <FormControl
          fullWidth
          margin="normal"
        >
          <DatePicker
            label={options.start_date?.label}
            value={startDate}
            inputFormat="YYYY-MM-DD"
            renderInput={(params) => <TextField {...params} />}
            shouldRespectLeadingZeros
            onChange={(d) => {
              handleDate(d?.format("YYYY-MM-DD") ?? null, 'start_date', setStartDate)
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
        >
          <DatePicker
            label={options.end_date?.label}
            value={endDate}
            inputFormat="YYYY-MM-DD"
            renderInput={(params) => <TextField {...params} />}
            shouldRespectLeadingZeros
            maxDate={new Date('2999-12-31')}
            onChange={(d) => {
              handleDate(d?.format("YYYY-MM-DD") ?? null, 'end_date', setEndDate)
            }}
          />
        </FormControl>
      </LocalizationProvider>
    </div>
  )
}

export default ProgrammeRuleEntry
