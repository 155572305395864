

const customIdStemRegex = RegExp(/^[A-Z]{3}$/)
const validateCustomIdStem = (id) => {
    return customIdStemRegex.test(id) ? null : 'Invalid Custom ID Stem, needs to contain 3 upper case letters only (eg ABC)'
}

const customIdRegex = RegExp(/^[A-Z]{3}-[A-Z]{3}$/)
const validateCustomId = (id) => customIdRegex.test(id)

const deal = {
    'custom_id_stem': validateCustomIdStem,
}

const validateDeal = (data) => {
    return Object.entries(data).map(([k, v]) => {
        if (!(k in deal)) return null
        return deal[k](v)
    }).filter(Boolean)
}

const validatorType = {
    'deals': validateDeal,
    'clients': () => [], //validateClients,
    'programmes': () => [] //validateProgrammes
}

const validate = (data, page) => {
    if (!(page in validatorType)) return null
    return validatorType[page](data)
}

export {
    validate,
    validateDeal
}
